import React, {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import "./resolution-management.css";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Loader } from "../components/loader";
import { Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import Dropzone from "dropzone";
import { PublicKey } from "@solana/web3.js";
import { createEscrowApproveTransaction } from "../util/escrow";
import S3 from "react-aws-s3";
import reject_img from "../images/rejected.png";
import comment_img from "../images/quotes.png";
import accept_img from "../images/accept.png";
import add_new_comment_img from "../images/comments.png";
import back_img from "../images/left-arrow.png";
import moment from "moment";

export const ResolutionDetails = ({ socket }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const { wallet, publicKey, sendTransaction } = useWallet();
  const [totalDisputes, settotalDisputes] = useState(0);
  const [milestones, setmilestones] = useState([]);
  const [buyerDisputes, setbuyerDisputes] = useState([]);
  const [sellerDisputes, setsellerDisputes] = useState([]);
  const [createDispute, setcreateDispute] = useState(false);
  const [disputePorjectId, setdisputePorjectId] = useState("");
  const [disputeMilestoneId, setdisputeMilestoneId] = useState("");
  const [disputeDiscription, setdisputeDiscription] = useState("");
  const [totalContract, settotalContract] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [milestoneTitle, setMilestoneTitle] = useState("");
  const [disputeRaisedBy, setDisputeRaisedBy] = useState("");
  const [buyerProfileName, setBuyerProfileName] = useState("");
  const [sellerProfileName, setSellerProfileName] = useState("");
  const [disputeViewReceived, SetDisputeViewReceived] = useState(false);
  const [disputeAmount, setDisputeAmount] = useState(0);
  const [disputeStatus, setDisputeStatus] = useState("");
  const [showComment, SetShowComment] = useState(false);
  const [acceptDispute, SetAcceptDispute] = useState(false);
  const [rejectDispute, SetRejectDispute] = useState(false);
  const [disputeComment, SetDisputeComment] = useState("");
  const [disputeCommentsHist, setDisputeCommentsHist] = useState([]);
  const [milestoneId, setMilestoneId] = useState("");
  const [milsetoneBased, setMilsetoneBased] = useState(false);
  const [escrowVaultWord, setEscrowVaultWord] = useState("");
  const [sellarAddress, setSellarAddress] = useState("");
  const [buyerAddress, setBuyerAddress] = useState("");
  const [projectId, setProjectId] = useState("");
  const { connection } = useConnection();
  const navigate = useNavigate();
  const fileInput: any = useRef();
  const [uploadSucess, setUploadSucess] = useState(false);
  const [showDetails, setshowDetails] = useState(false);
  const [rejectOrReleaseSuccess, setRejectOrReleaseSuccess] = useState(true);
  const [rejectionSucess, setRejectionSucess] = useState(false);
  const [releasePaymentSucess, setReleasePaymentSucess] = useState(false);
  const [isAttachmentAvailable, setIsAttachmentAvailable] = useState(false);
  const [fileNameFrDownload, setFileNameFrDownload] = useState();
  const [fileNameFrReq, setFileNameFrReq] = useState();
  const location = useLocation();
  const [current_user_type, setcurrent_user_type] = useState("");
  const [buyer_sellarView, setBuyer_SellarView] = useState(false);
  const [buyer_BuyerView, setBuyer_BuyerView] = useState(false);
  const [sellar_sellarView, setSellar_SellarView] = useState(false);
  const [selar_BuyerView, setSellar_BuyerView] = useState(false);
  const [showResolveDispute, setshowResolveDispute] = useState(false);
  const [showAddComment, setshowAddComment] = useState(false);

  const makeAPICall = async () => {
    try {
      // const response = await fetch('https://api.devnet-testing.thevault-x.com/api/v1/disputeView/' + publicKey.toString(), { mode: 'cors' });
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "getAllDisputes/" +
          publicKey.toString(),
        { mode: "cors" }
      );
      const data = await response.json();
      // settotalDisputes(data['buyerDisputes'].length + data['sellerDisputes'].length)
      setbuyerDisputes(data["buyerDisputes"]);
      // let seller_data = data['sellerDisputes'].filter(item => item['progress'] !== 'DRAFT')
      setsellerDisputes(data["sellerDisputes"]);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const init = useCallback(async () => {
    if (publicKey) {
      let acc = await connection.getAccountInfo(publicKey);
      setAccount(acc);
    }
  }, [publicKey, connection]);

  const disputeViewOnRefresh = async () => {
    try {
      let data = {
        milestoneId: milestoneId,
        milstoneBased: milsetoneBased,
        projectId: projectId,
        walletAddress: publicKey.toString(),
      };
      // let res = await fetch("https://api.devnet-testing.thevault-x.com/api/v1/acceptContract", {
      let res = await fetch(process.env.REACT_APP_API_URL + "disputeView", {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(data),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setshowResolveDispute(false);
        SetDisputeViewReceived(true);
        setProjectName(resJson.projectName);
        setMilestoneTitle(resJson.milestoneTitle);
        setDisputeRaisedBy(resJson.disputeRaisedBy);
        setDisputeCommentsHist(resJson.disputeComments);
        sellerOrBuyer(
          resJson.disputeRaisedBy,
          publicKey.toString(),
          resJson.buyerAddress,
          resJson.sellerAddress
        );
        if (resJson.status === "IN ARBITRATION") {
          setRejectionSucess(true);
          setRejectOrReleaseSuccess(false);
          if (resJson.attachment != null && resJson.attachment != "") {
            setIsAttachmentAvailable(true);
            setFileNameFrDownload(resJson.attachment);
          }
        }
        if (resJson.status === "Completed") {
          setReleasePaymentSucess(true);
          setRejectOrReleaseSuccess(false);
          if (resJson.attachment != null && resJson.attachment != "") {
            setIsAttachmentAvailable(true);
            setFileNameFrDownload(resJson.attachment);
          }
        }
        if (resJson.status === "IN DISPUTE") {
          setReleasePaymentSucess(false);
          setRejectionSucess(false);
          setRejectOrReleaseSuccess(true);
          if (resJson.attachment != null && resJson.attachment != "") {
            setIsAttachmentAvailable(true);
            setFileNameFrDownload(resJson.attachment);
          }
        }

        if (resJson.disputeRaisedBy == "BUYER") {
          setBuyerProfileName(resJson.buyerProfileName);
        } else {
          setSellerProfileName(resJson.sellerProfileName);
        }
        setDisputeAmount(resJson.disputeAmount);
        setDisputeStatus(resJson.status);
        console.log("success");
      } else {
        SetDisputeViewReceived(false);
        console.log("failure");
      }
    } catch (err) {
      SetDisputeViewReceived(false);
      console.log(err);
    }
  };
  function sellerOrBuyer(createdBy, p_key, buyerAddress, sellerAddress) {
    if (buyerAddress == p_key) {
      setcurrent_user_type("buyer");
    } else if (sellerAddress == p_key) {
      setcurrent_user_type("sellar");
    }
  }

  let call_dispute_details =
    (param1, param2, param3, param4, param5) => (e) => {

      const disputeView = async () => {
        try {
          let milestoneBased = param2 === "true";
          setMilestoneId(param1);
          setMilsetoneBased(milestoneBased);
          setProjectId(param3);
          setSellarAddress(param5);
          setBuyerAddress(param4);
          localStorage.setItem("d_m_id", param1);
          localStorage.setItem("d_p_id", param3);
          let data = {
            milestoneId: param1,
            milsetoneBased: milestoneBased,
            projectId: param3,
            walletAddress: publicKey.toString(),
          };

          // let res = await fetch("https://api.devnet-testing.thevault-x.com/api/v1/acceptContract", {
          let res = await fetch(process.env.REACT_APP_API_URL + "disputeView", {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify(data),
          });
          let resJson = await res.json();
          if (res.status === 200) {
            setshowDetails(true);
            SetDisputeViewReceived(true);
            sellerOrBuyer(
              resJson.disputeRaisedBy,
              publicKey.toString(),
              resJson.buyerAddress,
              resJson.sellerAddress
            );
            setProjectName(resJson.projectName);
            setMilestoneTitle(resJson.milestoneTitle);
            setDisputeRaisedBy(resJson.disputeRaisedBy);
            if (resJson.disputeComments) {
              setDisputeCommentsHist(resJson.disputeComments);
            } else {
              setDisputeCommentsHist([]);
            }
            setBuyerProfileName(resJson.buyerProfileName);
            setSellerProfileName(resJson.sellerProfileName);
            if (resJson.status === "IN ARBITRATION") {
              setRejectionSucess(true);
              setReleasePaymentSucess(false);
              setRejectOrReleaseSuccess(false);
              if (resJson.attachment != null && resJson.attachment != "") {
                setIsAttachmentAvailable(true);
                setFileNameFrDownload(resJson.attachment);
              }
            }
            if (resJson.status === "Completed") {
              setReleasePaymentSucess(true);
              setRejectionSucess(false);
              setRejectOrReleaseSuccess(false);
              if (resJson.attachment != null && resJson.attachment != "") {
                setIsAttachmentAvailable(true);
                setFileNameFrDownload(resJson.attachment);
              }
            }
            if (resJson.status === "IN DISPUTE") {
              setReleasePaymentSucess(false);
              setRejectionSucess(false);
              setRejectOrReleaseSuccess(true);
              if (resJson.attachment != null && resJson.attachment != "") {
                setIsAttachmentAvailable(true);
                setFileNameFrDownload(resJson.attachment);
              }
            }
            setDisputeAmount(resJson.disputeAmount);
            setDisputeStatus(resJson.status);
            console.log("success");
          } else {
            SetDisputeViewReceived(false);
            console.log("failure");
          }
        } catch (err) {
          SetDisputeViewReceived(false);
          console.log(err);
        }
      };
      disputeView();

      //navigate('/project-milestone/'+param);
    };

  const getDsiputeView = async (
    project_id,
    milestine_id,
    is_milestone_based
  ) => {
    try {
      let milestoneBased = is_milestone_based === "true";
      setMilestoneId(milestine_id);
      setMilsetoneBased(milestoneBased);
      setProjectId(project_id);
      // setSellarAddress(param5);
      // setBuyerAddress(param4);
      localStorage.setItem("d_m_id", milestine_id);
      localStorage.setItem("d_p_id", project_id);
      let data = {
        milestoneId: milestine_id,
        milsetoneBased: milestoneBased,
        projectId: project_id,
        walletAddress: publicKey.toString(),
      };
      setIsLoading(true);
      let res = await fetch(process.env.REACT_APP_API_URL + "disputeView", {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(data),
      });
      let resJson = await res.json();
      setIsLoading(false);
      if (res.status === 200) {
        setSellarAddress(resJson.sellerAddress);
        setBuyerAddress(resJson.buyerAddress);
        setshowDetails(true);
        SetDisputeViewReceived(true);
        sellerOrBuyer(
          resJson.disputeRaisedBy,
          publicKey.toString(),
          resJson.buyerAddress,
          resJson.sellerAddress
        );
        setProjectName(resJson.projectName);
        setMilestoneTitle(resJson.milestoneTitle);
        setDisputeRaisedBy(resJson.disputeRaisedBy);
        if (resJson.disputeComments) {
          setDisputeCommentsHist(resJson.disputeComments);
        } else {
          setDisputeCommentsHist([]);
        }
        setBuyerProfileName(resJson.buyerProfileName);
        setSellerProfileName(resJson.sellerProfileName);
        if (resJson.status === "IN ARBITRATION") {
          setRejectionSucess(true);
          setReleasePaymentSucess(false);
          setRejectOrReleaseSuccess(false);
          if (resJson.attachment != null && resJson.attachment != "") {
            setIsAttachmentAvailable(true);
            setFileNameFrDownload(resJson.attachment);
          }
        }
        if (resJson.status === "Completed") {
          setReleasePaymentSucess(true);
          setRejectionSucess(false);
          setRejectOrReleaseSuccess(false);
          if (resJson.attachment != null && resJson.attachment != "") {
            setIsAttachmentAvailable(true);
            setFileNameFrDownload(resJson.attachment);
          }
        }
        if (resJson.status === "IN DISPUTE") {
          setReleasePaymentSucess(false);
          setRejectionSucess(false);
          setRejectOrReleaseSuccess(true);
          if (resJson.attachment != null && resJson.attachment != "") {
            setIsAttachmentAvailable(true);
            setFileNameFrDownload(resJson.attachment);
          }
        }
        setDisputeAmount(resJson.disputeAmount);
        setDisputeStatus(resJson.status);
        console.log("success");
      } else {
        SetDisputeViewReceived(false);
        console.log("failure");
      }
    } catch (err) {
      SetDisputeViewReceived(false);
      console.log(err);
    }
  };
  useEffect(() => {
    console.log(location.pathname.split("/"));
    let t = location.pathname.split("/");
    if (t[2] != undefined && t[3] != undefined) {
      getDsiputeView(t[2], t[3], localStorage.getItem("re_is_m_based"));
    }

    // setshowDetails(false);
    // Send request to your server to increment page view count
  }, [location]);

  useEffect(() => {
    if (publicKey) {
      localStorage.setItem("p_key", publicKey.toString());
      //   setInterval(init, 1000);
    }
  }, [init, publicKey]);

  // const [acceptDispute, SetAcceptDispute] = useState(false);
  // const [rejectDispute, SetRejectDispute] = useState(false);
  // const [addDisputeCmnt, SetAddDisputeCmnt] = useState(false);
  function comments() {
    setshowAddComment(true);
    SetShowComment(true);
    SetAcceptDispute(false);
    SetRejectDispute(false);
  }
  function reject() {
    SetShowComment(false);
    SetAcceptDispute(false);
    SetRejectDispute(true);
    SetDisputeComment("");
    Swal.fire({
      text: "Are you sure to reject this dispute and raise arbitration?",
      showDenyButton: true,
      confirmButtonText: "Confirm",
      denyButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        submitDispute();
      } else if (result.isDenied) {
      }
    });
  }
  function accept() {
    SetShowComment(false);
    SetAcceptDispute(true);
    SetRejectDispute(false);
    SetDisputeComment("");
    Swal.fire({
      text: "Tamper-proof confirmation. You can't reverse this transaction.",
      showDenyButton: true,
      confirmButtonText: "Confirm",
      denyButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        submitDispute();
      } else if (result.isDenied) {
      }
    });
  }

  function submitDispute() {
    setUploadSucess(false);
    if (acceptDispute) {
      // acceptAPi()
      release_payment_confirmed();
    }
    if (rejectDispute) {
      rejectAPi();
    }
    if (showComment) {
      disputeCommentAPi();
    }
  }

  const sendAndConfirmTransaction = async (transaction) => {
    try {
      let { blockhash } = await connection.getRecentBlockhash();
      transaction.feePayer = publicKey!;
      transaction.recentBlockhash = blockhash;

      let signature = await sendTransaction(transaction, connection, {skipPreflight:false});
      await connection.confirmTransaction(signature, "recent");

      // if (onChange) onChange();

      return signature;
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      let message = error.msg;
      let signatureInError = "";
      console.log("error on solana transaction timeout", message);
      if (message.indexOf("Transaction was not confirmed") > -1) {
        signatureInError = message.substring(
          message.indexOf("signature") + 10,
          message.indexOf("using") - 1
        );
        console.log(signatureInError);
        console.log(signatureInError.length);
      }
      if (signatureInError) {
        console.log("inside pre error", signatureInError);
        Swal.fire({
          text: "Transaction Failed. Please retry.",
          confirmButtonText: "Retry",
        }).then(() => {
          console.log("pre inside then #", signatureInError);
          console.log("pre inside then wallet", publicKey);
          if (signatureInError && publicKey) {
            // fuseRetry(signatureInError, publicKey); // call api again here
          }
        });
      }
    }
  };
  async function release_payment_confirmed() {
    localStorage.setItem("last_api_call", "release_payment");
    setIsLoading(true);
    try {
      let access_res = await fetch(
        process.env.REACT_APP_API_URL +
          "getAccessKey/" +
          localStorage.getItem("d_p_id") +
          "/" +
          localStorage.getItem("d_m_id"),
        {
          mode: "cors",
        }
      );
      let resJson = await access_res.text();
      localStorage.setItem("d_rp_a_k", resJson);
      setEscrowVaultWord(resJson);
      await EscrowApprove();
    } catch (e) {
      console.log(e);
    }
  }

  /**API for accept Dispute */
  const EscrowApprove = useCallback(async () => {
    let sig = await sendAndConfirmTransaction(
      await createEscrowApproveTransaction(
        connection,
        new PublicKey(buyerAddress),
        new PublicKey(sellarAddress),
        localStorage.getItem("d_rp_a_k")
      )
    );
    try {
      if (sig) {
        try {
          let data = {
            milestoneId: localStorage.getItem("d_m_id"),
            projectId: localStorage.getItem("d_p_id"),
            signature: sig,
            walletAddress: publicKey.toString(),
            attachment: fileNameFrReq,
          };
          setIsLoading(true);
          // let res = await fetch("https://api.devnet-testing.thevault-x.com/api/v1/acceptContract", {
          let res = await fetch(
            process.env.REACT_APP_API_URL + "releasePayment",
            {
              method: "POST",
              mode: "cors",
              headers: new Headers({ "content-type": "application/json" }),
              body: JSON.stringify(data),
            }
          );
          let resJson = await res.json();
          if (res.status === 200) {
            setIsLoading(false);
            setRejectOrReleaseSuccess(false);
            setReleasePaymentSucess(true);
            if (publicKey.toString() == sellarAddress) {
              notify_user(
                "Seller released payment the for dispute",
                buyerAddress
              );
            } else {
              notify_user(
                "Buyer has released payment for dispute.",
                sellarAddress
              );
            }
            Swal.fire({
              text: "Payment released.",
              confirmButtonText: "Close",
            }).then(() => {
              disputeViewOnRefresh();
            });
            console.log("success");
          } else {
            console.log("failure");
            setRejectOrReleaseSuccess(true);
            setReleasePaymentSucess(false);
          }
        } catch (e) {
          setIsLoading(false);
        }
      }
    } catch (e) {}
  }, [connection, publicKey, sendAndConfirmTransaction]);

  /**API for reject Dispute */
  const rejectAPi = async () => {
    try {
      let data = {
        milestoneId: milestoneId,
        milsetoneBased: milsetoneBased,
        projectId: projectId,
        walletAddress: publicKey.toString(),
        attachment: fileNameFrReq,
      };
      // let res = await fetch("https://api.devnet-testing.thevault-x.com/api/v1/acceptContract", {
      let res = await fetch(process.env.REACT_APP_API_URL + "disputeReject", {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(data),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        console.log("success");
        setRejectOrReleaseSuccess(false);
        setRejectionSucess(true);
        if (publicKey.toString() == sellarAddress) {
          notify_user("Seller has rejected the dispute.", buyerAddress);
        } else {
          notify_user("Buyer has rejected the dispute.", sellarAddress);
        }
        Swal.fire({
          text: "Your dispute reject request is submitted. Our team will get back to you shortly.",
          confirmButtonText: "Ok",
        }).then(() => {
          disputeViewOnRefresh();
        });
      } else {
        console.log("failure");
        setRejectOrReleaseSuccess(true);
        setRejectionSucess(false);
      }
    } catch (e) {}
  };
  /**API for Comments on dispute*/
  const disputeCommentAPi = async () => {
    try {
      if (disputeComment) {
        let data = {
          description: disputeComment,
          disputeComment: disputeComment,
          milestoneId: milestoneId,
          milstoneBased: milsetoneBased,
          projectId: projectId,
          walletAddress: publicKey.toString(),
          attachment: fileNameFrReq,
        };
        // let res = await fetch("https://api.devnet-testing.thevault-x.com/api/v1/acceptContract", {
        let res = await fetch(
          process.env.REACT_APP_API_URL + "disputeComment",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify(data),
          }
        );
        let resJson = await res.json();
        setshowAddComment(false);
        if (res.status === 200) {
          console.log("success");
          SetDisputeComment("");

          if (publicKey.toString() == sellarAddress) {
            notify_user("Seller has commented on the dispute.", buyerAddress);
          } else {
            notify_user("Buyer has commented on the dispute.", sellarAddress);
          }

          Swal.fire({
            text: "Your dispute comment has been submitted. Our team will get back to you shortly.",
            confirmButtonText: "Ok",
          }).then(() => {
            disputeViewOnRefresh();
          });
        } else {
          console.log("failure");
        }
      } else {
        Swal.fire({
          text: "Please enter comments to proceed.",
          confirmButtonText: "Ok",
        });
      }
    } catch (e) {}
  };

  const handleChange = (event) => {
    SetDisputeComment(event.target.value);
  };

  const handleClick = (event) => {
    event.preventDefault();
    setUploadSucess(false);
    if (fileInput) {
      let file = fileInput!.current.files[0];
      if (file) {
        let newFileName = fileInput!.current.files[0].name.replace(/\..+$/, "");
        newFileName =
          projectId.substring(0, projectId.indexOf("-")) +
          "_" +
          milestoneId.substring(0, milestoneId.indexOf("-")) +
          "_" +
          Math.random() +
          file.name;
        setFileNameFrReq(newFileName);
        const config = {
          bucketName: process.env.REACT_APP_BUCKET_NAME,
          //   dirName: process.env.REACT_APP_DIR_NAME /* optional */,
          region: process.env.REACT_APP_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_ID,
          secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
          s3Url: process.env.REACT_APP_S3URL,
        };
        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(file, newFileName).then((data) => {
          if (data.status === 204) {
            console.log("success");
            fileInput.current.value = "";
            setUploadSucess(true);
          } else {
            console.log("fail");
            fileInput.current.value = "";
            setUploadSucess(false);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (disputePorjectId && disputePorjectId != "Choose the Project") {
      let selected_contract = totalContract.find((element) => {
        return element.projectId === disputePorjectId;
      });

      setmilestones(selected_contract["mileStones"]);
    }
  }, [disputePorjectId]);
  const download = async (fileName) => {
    try {
      setIsLoading(true);
      await fetch(process.env.REACT_APP_API_URL + "download/" + fileName)
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = "vaultx";
          a.click();
        });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  const downloadRejectAccept = async () => {
    try {
      setIsLoading(true);
      await fetch(
        process.env.REACT_APP_API_URL + "download/" + fileNameFrDownload
      )
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = "vaultx";
          a.click();
        });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  let notify_user = async (msg, to_address) => {
    let res = await fetch(process.env.REACT_APP_API_URL + "saveMessage", {
      method: "POST",
      mode: "cors",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({
        fromAddress: publicKey.toString(),
        message: msg,
        milestoneId: localStorage.getItem("m_id"),
        projectId: localStorage.getItem("p_id"),
        readStatus: false,
        toAddress: to_address,
        messageType: "dispute",
      }),
    });
    let resJson = await res.json();
    if (res.status === 200) {
      socket?.emit("sendText", {
        message: msg,
        from: publicKey.toString(),
        to: to_address,
      });
    } else {
      console.log("failure");
    }
  };

  function findArrayElement(array, selected_projectId) {
    return array.find((element) => {
      return element.projectId == selected_projectId;
    });
  }

  const gettime = (timestamp) => {
    var stillUtc = moment.utc(timestamp, "YYYY-MM-DD HH:mm:ss a").toDate();
    var local = moment(stillUtc, "YYYY-MM-DD HH:mm:ss a")
      .local()
      .format("MMMM Do YYYY, h:mm a");

    return local;
  };

  const back_resolution_list = () => {
    navigate("/resolution-management");
  };

  return (
    <>
      {isLoading && <Loader />}

      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="container-xxl dispute-detail-container" id="kt_content_container">
          <div className="row g-5 g-xl-8">
            <div className="col-xl-11">
              {showDetails && (
                <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                  <div className="card card-flush pt-3 mb-5 mb-xl-10">
                    <div className="card-header">
                      <div className="card-title">
                        <h4 className="mb-4">Dispute Details:</h4>
                      </div>
                      <div className="card-toolbar">
                        <a
                          className="btn btn-md btn-back-dispute me-3"
                          onClick={() => back_resolution_list()}
                          data-bs-toggle="tab"
                        >
                          {/* <img src={back_img} width={20} /> */}
                          Back To Dispute List
                        </a>
                      </div>
                    </div>

                    <div className="separator separator-dashed mb-7"></div>
                    <div className="card-body pt-3">
                      <div className="mb-10">
                        <div className="d-flex flex-wrap py-5">
                          <div className="flex-equal me-5">
                            <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                              <tbody>
                                <tr>
                                  <td className="text-gray-400 min-w-175px w-175px">
                                    Project name:
                                  </td>
                                  <td className=" min-w-200px">
                                    {projectName}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-gray-400">
                                    Dispute Raised By :
                                  </td>
                                  <td className="">
                                    {disputeRaisedBy}
                                  </td>
                                </tr>

                                <tr>
                                  <td className="text-gray-400">
                                    Buyer Details:
                                  </td>
                                  <td className="">
                                    {buyerProfileName}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-gray-400">
                                    Dispute Amount:
                                  </td>
                                  <td className="">
                                    {disputeAmount} ◎
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="flex-equal">
                            <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                              <tbody>
                                <tr>
                                  <td className="text-gray-400">
                                    MileStone Title:
                                  </td>
                                  <td className="">
                                    {milestoneTitle}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-gray-400">Status:</td>
                                  <td className="">
                                    {disputeStatus == "IN DISPUTE"
                                      ? "In Dispute"
                                      : ""}
                                    {disputeStatus == "IN ARBITRATION"
                                      ? "Dispute Resolution in progress"
                                      : ""}
                                    {disputeStatus == "Completed"
                                      ? " Completed"
                                      : ""}
                                  </td>
                                </tr>

                                <tr>
                                  <td className="text-gray-400">
                                    Seller Details:
                                  </td>
                                  <td className="">
                                    {sellerProfileName}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="mb-0">
                        <div className="separator separator-dashed mb-7"></div>
                        <div className="row g-0 mb-7">
                          <div className="col">
                            <h4 className="mb-4">Previous Comments:</h4>
                          </div>

                          <div className="col">
                            {rejectOrReleaseSuccess && (
                              <a
                                className="btn btn-md  me-3 add-new-comment-btn"
                                onClick={comments}
                                data-bs-toggle="tab"
                              >
                                <img
                                  className=""
                                  src={add_new_comment_img}
                                  width={20}
                                />
                                Add New Comment
                              </a>
                            )}
                          </div>
                        </div>

                        <div className="table-responsive">
                          <table className="table align-middle fs-6 gy-4 mb-0">
                            <thead>
                              <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                <th className="min-w-150px">Comment By</th>
                                <th className="min-w-125px">Comment On</th>
                                <th className="min-w-125px">Comment</th>
                                <th className="min-w-125px">Attachment</th>
                              </tr>
                            </thead>

                            {disputeCommentsHist.length > 0 && (
                              <tbody className="fw-bold text-gray-800">
                                {disputeCommentsHist.map((disputeComment) => (
                                  <tr>
                                    <td>
                                      <label className="w-150px">
                                        {disputeComment.commentByProfileName
                                          ? disputeComment.commentByProfileName
                                          : disputeComment.commentBy.substring(
                                              0,
                                              4
                                            )}
                                      </label>
                                    </td>
                                    <td>
                                      {gettime(disputeComment.commentedTime)}
                                    </td>
                                    <td>{disputeComment.commentText}</td>
                                    <td>
                                      {disputeComment.attachment && (
                                        <a
                                          href="#"
                                          onClick={() =>
                                            download(disputeComment.attachment)
                                          }
                                        >
                                          <div>Download</div>
                                          <i className="bi bi-file-earmark-arrow-down text-primary fs-3x"></i>
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            )}
                            {disputeCommentsHist.length == 0 && (
                              <tbody className="fw-bold text-gray-800">
                                <tr>
                                  <td className="text-center" colSpan={4}>No Comments</td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>

                      <div className="flex-container mt-7">
                        <div className="separator separator-dashed mb-7"></div>
                        <div className="plans">
                          {rejectOrReleaseSuccess &&
                            disputeRaisedBy == "SELLER" &&
                            current_user_type != "sellar" && (
                              <label
                                className="plan basic-plan"
                                onClick={accept}
                              >
                                <input type="radio" name="plan" id="basic" />
                                <div className="plan-content">
                                  <img loading="lazy" src={accept_img} alt="" />
                                  <div className="plan-details">
                                    <span>
                                      Accept Dispute and Release the Payment of{" "}
                                      {disputeAmount}◎ to{" "}
                                      {sellerProfileName
                                        ? sellerProfileName
                                        : sellarAddress.substring(0, 4)}
                                    </span>
                                  </div>
                                </div>
                              </label>
                            )}
                          {rejectOrReleaseSuccess && (
                            <label className="plan basic-plan" onClick={reject}>
                              <input type="radio" name="plan" id="basic" />
                              <div className="plan-content">
                                <img loading="lazy" src={reject_img} alt="" />
                                <div className="plan-details">
                                  <span>
                                    {" "}
                                    Reject Dispute and Raise for Resolution
                                  </span>
                                </div>
                              </div>
                            </label>
                          )}
                        </div>
                      </div>

                      {!rejectOrReleaseSuccess && rejectionSucess && (
                        <div style={{ paddingTop: "15px" }} className="fv-row">
                          <div className="dispute-status-card d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                            <h3 className="fs-5 fw-bolder text-primary mb-1">
                              Dispute Rejection Successful.
                            </h3>
                            <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2"></div>
                            {/* <div className="min-w-125px pe-2">
                              <div className="text-center">
                                <a href="#" onClick={downloadRejectAccept}>
                                  <div>Download Attachment</div>
                                  <i className="bi bi-file-earmark-arrow-down text-primary fs-3x"></i>
                                </a>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      )}
                      {!rejectOrReleaseSuccess && releasePaymentSucess && (
                        <div style={{ paddingTop: "15px" }} className="fv-row">
                          <div className="dispute-status-card d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                            <h3 className="fs-5 fw-bolder text-primary mb-1">
                              Dispute Acceptance Successful.
                            </h3>
                            <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2"></div>
                            {/* <div className="min-w-125px pe-2">
                              <div className="text-center">
                                <a href="#" onClick={downloadRejectAccept}>
                                  <div>Download Attachment</div>
                                  <i className="bi bi-file-earmark-arrow-down text-primary fs-3x"></i>
                                </a>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/*To add here */}
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          show={showResolveDispute}
          onHide={() => setshowResolveDispute(false)}
          dialogClassName="modal-r90w"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Resolve Dispute</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              id="kt_accordion_2_item_1"
              className="fs-6 collapse show ps-10"
              data-bs-parent="#kt_accordion_2"
            >
              {rejectOrReleaseSuccess && (
                <h5 className="mb-4 text-primary">Choose Your Option:</h5>
              )}
              <div className="flex-container">
                <div className="plans">
                  {rejectOrReleaseSuccess &&
                    disputeRaisedBy == "SELLER" &&
                    current_user_type != "sellar" && (
                      <label className="plan basic-plan" onClick={accept}>
                        <input type="radio" name="plan" id="basic" />
                        <div className="plan-content">
                          <img loading="lazy" src={accept_img} alt="" />
                          <div className="plan-details">
                            <span>
                              Accept Dispute and Release the Payment of{" "}
                              {disputeAmount}◎ to{" "}
                              {sellerProfileName
                                ? sellerProfileName
                                : sellarAddress.substring(0, 4)}
                            </span>
                          </div>
                        </div>
                      </label>
                    )}
                  {rejectOrReleaseSuccess && (
                    <label className="plan basic-plan" onClick={reject}>
                      <input type="radio" name="plan" id="basic" />
                      <div className="plan-content">
                        <img loading="lazy" src={reject_img} alt="" />
                        <div className="plan-details">
                          <span> Reject Dispute and Raise for Resolution</span>
                        </div>
                      </div>
                    </label>
                  )}

                  {rejectOrReleaseSuccess && (
                    <label className="plan complete-plan" onClick={comments}>
                      <input type="radio" id="complete" name="plan" />
                      <div className="plan-content">
                        <img loading="lazy" src={comment_img} alt="" />
                        <div className="plan-details">
                          <span> Add Comment (Dispute Remain Opens)</span>
                        </div>
                      </div>
                    </label>
                  )}
                </div>
              </div>

              {!rejectOrReleaseSuccess &&
              rejectionSucess ? null : !rejectOrReleaseSuccess &&
                releasePaymentSucess ? null : (
                <div className="text-center" style={{ paddingTop: "10px" }}>
                  <a
                    href="#"
                    onClick={() => setshowResolveDispute(false)}
                    className="btn btn-dispute-cancel me-3"
                  >
                    Cancel
                  </a>
                  <a
                    href="#"
                    onClick={submitDispute}
                    className="btn btn-primary btn-dispute-submit"
                  >
                    Submit
                  </a>
                </div>
              )}
              {/* <div style={{ paddingTop: "10px" }}><a href="#" className="btn btn-secondary">cancel</a>   <a href="#" onClick={submitDispute} className="btn btn-secondary">Submit</a>
</div> */}
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showAddComment}
          onHide={() => setshowAddComment(false)}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <div className="d-flex flex-column mb-8 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                  <span className="">Comments</span>
                </label>
                <textarea
                  className="form-control form-control-solid"
                  rows={3}
                  onChange={handleChange}
                ></textarea>
              </div>
              <form
                className="form"
                action="#"
                method="post"
                onSubmit={handleClick}
              >
                <div className="fv-row">
                  <div className="dropzone" id="kt_dropzonejs_example_1">
                    <div className="dz-message needsclick">
                      {/* <i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i> */}
                      <div className="ms-4">
                        <div className="image-upload d-flex flex-column fv-row">
                          <label htmlFor="file-input">
                            <span className="fs-6 fw-bold">Attach File</span>
                          </label>
                          <input
                            type="file"
                            ref={fileInput}
                            onClick={() => setUploadSucess(false)}
                            accept=".zip,.rar,.7zip,application/pdf, application/vnd.ms-excel, image/*"
                          />
                        </div>
                        <span></span>
                        <div className="pt-3">
                          <button
                            type="submit"
                            className="btn btn-sm me-5 dispute-upload-btn"
                          >
                            Upload
                          </button>
                        </div>
                        {uploadSucess && (
                          <div>
                            <span className="badge badge-light-success ms-2 fs-7">
                              Upload is successful!
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="text-center" style={{ paddingTop: "10px" }}>
                <a
                  href="#"
                  onClick={() => setshowAddComment(false)}
                  className="btn btn-dispute-cancel me-3"
                >
                  Cancel
                </a>
                <a
                  href="#"
                  onClick={submitDispute}
                  className="btn btn-primary btn-dispute-submit"
                >
                  Submit
                </a>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
